<template>
  <div>
    <div style="margin-bottom: 20px">
      <el-form ref="form" :model="form">
        <el-row :gutter="10">
          <el-col :span="10">
            <el-form-item label="时间范围" style="text-align: left">
              <el-date-picker
                  style="width: 80%"
                  type="daterange"
                  v-model="form.rangeTime"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  :default-time="['00:00:00', '23:59:59']">
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item style="text-align: left">
              <el-button type="primary" @click="onSearch">搜索</el-button>
              <el-button @click="onCancel">取消</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <el-table :data="list" border :max-height="maxHeight" v-loading="loading">
      <el-table-column prop="order_id" label="订单ID" fixed="left"></el-table-column>
      <el-table-column prop="title" label="商品名称">
        <template slot-scope="scope">
          <div>{{ scope.row.order_detail.product_infos[0].title }}</div>
        </template>
      </el-table-column>
      <el-table-column prop="thumb_img" label="商品封面" align="center">
        <template slot-scope="scope">
          <el-image :src="scope.row.order_detail.product_infos[0].thumb_img" fit="contain" style="width: 80px"/>
        </template>
      </el-table-column>
      <el-table-column prop="price_info" label="订单金额">
        <template slot-scope="scope"> ￥{{ scope.row.order_detail.price_info.order_price / 100 }}</template>
      </el-table-column>
      <el-table-column prop="phone" label="购买人手机号">
        <template slot-scope="scope"> {{ scope.row.order_detail.delivery_info.address_info.virtual_order_tel_number }}</template>
      </el-table-column>
      <el-table-column prop="create_time" label="创建时间">
        <template slot-scope="scope"> {{ formatTime(scope.row.create_time * 1000) }}</template>
      </el-table-column>
      <el-table-column label="操作" align="center" fixed="right">
        <template slot-scope="scope">
          <el-button type="text" size="small" @click="onShipOrder(scope.row)">发货</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog
        title="订单发货"
        :visible.sync="dialogVisible"
        width="500px">
      <div>
        <el-select v-model="value" placeholder="请选择兑换的商品" style="width: 100%">
          <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value">
          </el-option>
        </el-select>
      </div>
        <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="onSelect">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import OrderApi from "../../request/OrderApi";
import dayjs from "dayjs";
import CourseApi from "@/request/CourseApi.js";

export default {
  data() {
    return {
      page: 1,
      list: [],
      loading: true,
      hasMore: false,
      nextKey: undefined,
      form: {
        rangeTime: [new Date(dayjs().subtract(7, "day").toDate()), new Date(dayjs().toDate())],
      },
      row: {},
      dialogVisible: false,
      maxHeight: document.documentElement.clientHeight - 200,
      options: [],
      value: ''
    };
  },
  mounted() {
    const start_time = +(new Date(dayjs().startOf('day').toDate()).getTime() / 1000).toFixed(0);
    const end_time = +(new Date(dayjs().add(6, 'day').endOf('day').toDate()).getTime() / 1000).toFixed(0);
    this.loadData(start_time, end_time);
  },

  methods: {
    loadData(start_time, end_time) {
      OrderApi.wxOrderList({
        start_time: start_time,
        end_time: end_time,
        status: 20,
        page_size: 99
      }).then((res) => {
        this.loading = false;
        if (res.success) {
          this.list = res.data.order_list;
          this.hasMore = res.data.has_more;
          this.nextKey = res.data.next_key;
        }
      });

      CourseApi.list({
        pageNo: this.page,
        pageSize: 1000,
      }).then((res) => {
        if (res.success) {
          this.options = res.data.map(item => {
            return {
              value: item.skuId,
              label: item.skuName,
            }
          })
        }
      });

    },

    handleChangePage(val) {
      this.page = val;
      console.log(val, 'val');
    },

    formatTime(val) {
      return dayjs(val).format("YYYY-MM-DD HH:mm:ss");
    },

    async onCancel() {
      const start_time = +(new Date(dayjs().startOf('day').toDate()).getTime() / 1000).toFixed(0);
      const end_time = +(new Date(dayjs().add(6, 'day').endOf('day').toDate()).getTime() / 1000).toFixed(0);
      await this.loadData(start_time, end_time);
    },

    async onSearch() {
      const [starDate, endDate] = this.form.rangeTime;
      const start_time = +(new Date(dayjs(starDate).startOf('day').toDate()).getTime() / 1000).toFixed(0);
      const end_time = +(new Date(dayjs(endDate).endOf('day').toDate()).getTime() / 1000).toFixed(0);
      await this.loadData(start_time, end_time);
    },

    onShipOrder(row) {
      this.row = row;
      this.dialogVisible = true;
    },

    async onSelect() {
      if (!this.value) {
        this.$message.warning('请选择兑换的课程!');
        return;
      }
      const result = await OrderApi.wxOrderShop({
        mini_sku_id: this.value,
        order_id: this.row.order_id,
        sku_id: this.row.order_detail.product_infos[0].sku_id,
        product_id: this.row.order_detail.product_infos[0].product_id,
        phone: this.row.order_detail.delivery_info.address_info.virtual_order_tel_number,
      });
      this.dialogVisible = false;
      if (result.success) {
        this.$message.success('发货成功!');
      } else {
        this.$message.error('出错啦!');
      }
    }
  },
};
</script>

<style></style>
